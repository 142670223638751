<template>
  <div class="content-search-transation-detail">
    <div class="content-text-title">
      <v-skeleton-loader width="auto" type="button"></v-skeleton-loader>
      <p class="text-title">
        <v-skeleton-loader width="200px" type="text"></v-skeleton-loader>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router
        .push({
          name: "AccountDetail",
          params: { id: this.$route.params.id },
        })
        .catch(() => {});
    },
  },
  computed: {
    sNameDetail() {
      return this.$store.state.sNameDetail;
    },
  },
};
</script>
<style scoped>
.content-search-transation-detail {
  display: flex;
  align-content: center;
  width: 100%;
}
.content-text-title {
  display: flex;
}
.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 2rem;
  margin-bottom: 0px;
  margin-right: 5px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>